import { inject } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  queryConfigurationFragment,
  queryGroupFragment,
  queryGroupFragmentRecursive,
  queryIdentifierFragment,
  queryItemFragment,
} from '@clean-code/feature/query-builder/api-query-builder';
import {
  DataTableParameters,
  ID,
  ObjectHelper,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse } from '@datorama/akita';
import { Observable, map } from 'rxjs';
import { UserLimit } from '../entities/user-limit';
import { UserLimitService } from './user-limit.service';

export abstract class UserLimitServiceBase implements UserLimitService {
  protected graphqlService = inject(GraphqlService);

  protected entityFields = `
    id
    name
    effectiveUntil
    deleteAfterViolation
    notificationMail
    notificationInApp
    isExpired
    disabled
    owner
    {
      id
      firstName
      lastName
      fullName
    }

`;

  protected inputFields = `
  id
  name
  personalLimit
  isLowerLimit
  effectiveUntil
  deleteAfterViolation
  notificationMail
  notificationInApp
  disabled
  timeSeriesIdentifierData {
    id
    date
    identifier
  }
`;

  protected queryName = 'userLimits';
  protected mutationName = 'userLimit';

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<UserLimit>> {
    const param = this.graphqlService.mapDataTableParameters(params);
    const order = this.graphqlService.mapDataTableSortParameters(params.sortBy);
    const where = this.graphqlService.mapDataTableFilterParameters(
      params.filters
    );

    return this.getAllInternal$(
      param,
      order,
      where,
      params.page,
      params.perPage
    );
  }

  protected getAllInternal$(
    param: string,
    order: string,
    where: string,
    page?: number,
    perPage?: number
  ) {
    const query = `
    query userLimits {
        ${this.queryName}(
            ${param}
            ${order}
            ${where}
        ) {
          items {
            ${this.entityFields}
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
    }
    `;

    return this.graphqlService
      .query<PagingResponse<UserLimit>>(query)
      .pipe(mapGraphqlPaging(page, perPage));
  }

  getById$(id: ID): Observable<UserLimit> {
    const query = `
        query userLimits ($id: Int!) {
            ${this.queryName}(
                order: {
                    effectiveUntil: ASC, 
                    name: ASC
                },
                where: {
                    id: { eq: $id}
                }
            ) {
              items {
                ${this.entityFields}
                queryBuilder
                {
                  id
                  configuration
                  configurationObj
                  {
                    ...ConfigurationFragment
                  }
               
                }
              }
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              totalCount
            }
        }
        ${queryItemFragment}
        ${queryIdentifierFragment}
        ${queryGroupFragment}
        ${queryGroupFragmentRecursive}
        ${queryConfigurationFragment}
    `;

    const variables = { id };
    return this.graphqlService
      .query<PagingResponse<UserLimit>>(query, variables, null, {
        redirectTo404: true,
        check404Property: 'items',
      })
      .pipe(
        mapGraphqlPaging(1, 1),
        map((value) => value.data[0]), //todo: throw error?,
        map((value: UserLimit) => {
          const ul = {
            ...value,
            queryBuilder: {
              ...value.queryBuilder,
              configuration: value.queryBuilder.configurationObj,
            },
          };

          return ul;
        })
      );
  }

  add$(userLimit: UserLimit): Observable<UserLimit | ID> {
    const query = `
    mutation addUserLimit($userLimit: UserLimitAddInput!) {
        ${this.mutationName}Add(input: $userLimit) {
          ${this.entityFields}
        }
      }
    `;

    delete userLimit.id;
    delete userLimit.isExpired;

    const variables = { userLimit };

    return this.graphqlService.query<UserLimit>(query, variables);
  }

  public update$(userLimit: UserLimit): Observable<ID> {
    const query = `
    mutation updateUserLimit($userLimit: UserLimitUpdateInput!) {
      ${this.mutationName}Update(update: $userLimit) 
      }
    `;

    const limitInternal = ObjectHelper.cloneObject(userLimit);

    const variables = { userLimit: limitInternal };

    return this.graphqlService.query<ID>(query, variables);
  }

  public delete$(id: ID): Observable<boolean> {
    const query = `
        mutation deleteUserLimit($id: Int!) {
          ${this.mutationName}Delete(id: $id)
        }
    `;

    const variables = { id };
    return this.graphqlService.query<boolean>(query, variables, null, {
      ignoreNullCheckFilter: true,
    });
  }
}
