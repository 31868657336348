import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Query } from '@clean-code/feature/query-builder/domain';
import { ID } from '@clean-code/shared/common';
import {
  QueryGroupForm,
  QueryGroupInputForm,
} from './query-builder-group/query-builder-group-form';

export interface QueryForm {
  id: FormControl<ID>;
  configuration: FormGroup<QueryConfigurationForm>;
}

export interface QueryConfigurationForm {
  group: FormGroup<QueryGroupForm>;
}

export class QueryInputForm extends FormGroup<QueryForm> {
  constructor(
    readonly model?: Query,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group<QueryForm>({
        id: new FormControl<ID>(null),
        configuration: new FormGroup<QueryConfigurationForm>({
          group: new QueryGroupInputForm(model?.configuration?.group),
        }),
      }).controls
    );

    if (model) {
      this.patchValue(model as unknown);
    }
  }
}
